import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="text-center mt-5">
      <h1>ERRORE 404: PAGINA NON TROVATA</h1>
      <p>La pagina richiesta non è stata trovata.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
